<template>
  <base-page-item>
    <v-timeline-item
      left
      class="task-item pa-3"
      :color="active ? 'blue lighten-2' : 'grey lighten-2'"
      :icon-color="active ? 'blue lighten-2' : 'grey lighten-2'"
      small
    >
      <template v-slot:opposite>
        <div>
          <div class="text-left mb-0">
            <span class="font-weight-bold mr-1">Updated at: </span>{{ (task.updated_at || task.finished_at) | fromNow }}
          </div>
          <div class="text-left mb-0">
            <span class="font-weight-bold mr-1">Task type: </span>{{ task.type }}
          </div>
        </div>
      </template>
      <v-row
        align="center"
        class="spacer"
        no-gutters
        justify="center"
      >
        <v-col cols="12">
          <div class="text-left mb-0">
            <span class="font-weight-bold mr-1">Task status: </span>
            <span :class="[color.status]"> {{ task.status }} </span>
          </div>
          <div class="text-left mb-0">
            <span class="font-weight-bold mr-1">Task result: </span>
            <span :class="[color.result]">{{ result || 'none' }}</span>
          </div>
        </v-col>
      </v-row>
    </v-timeline-item>
  </base-page-item>
</template>

<script>
  import BasePageItem from '@/components/base/BasePageItem';
  import { fromNowFilterMixin } from '@/mixins/fromNowFilterMixin';

  export default {
    name: 'TaskItem',
    components: {
      BasePageItem,
    },
    mixins: [fromNowFilterMixin],
    props: {
      task: {
        type: Object,
        default: () => {},
      },
      active: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      result () {
        return this.task.result || this.task.data?.result || '';
      },
      color () {
        const colors = {
          status: '',
          result: '',
        };
        switch (this.task.status) {
        case 'done':
          colors.status = 'green--text text--darken-1';
          break;
        case 'success':
          colors.status = 'green--text text--darken-1';
          break;
        case 'new':
          colors.status = 'orange--text';
          break;
        case 'wip':
          colors.status = 'deep-purple--text';
          break;
        case 'failed':
          colors.status = 'red--text';
          break;
        default:
          colors.status = 'blue-grey--text';
          break;
        }

        switch (this.result) {
        case 'success':
          colors.result = 'green--text   text--lighten-1';
          break;
        case 'limited':
          colors.result = 'deep-orange--text  text--lighten-1';
          break;
        case 'security_code':
          colors.result = 'deep-purple--text  text--lighten-1';
          break;
        case 'failed':
          colors.result = 'red--text text--lighten-1';
          break;
        default:
          colors.result = 'blue-grey--text text--lighten-1';
          break;
        }

        return colors;
      },
    },
  };
</script>

<style scoped lang="scss">

.task-item {
  transition-duration: .5s;
  cursor: pointer;

  &:hover {
    background: rgba(black, .03);
  }
}

</style>
